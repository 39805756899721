body {
    background: black;
}

section {
    padding: 5% 0;
}
#continueButton{
    color: red!important;
}
.logo {
    padding: 20px 0;
}

.c-nav-container {
    display: flex;

    .menu {
        width: 70%;
    }
}

.text-small{
    font-size: 12px;
}
hr {
    &.light {
        border-bottom: 1px solid $white;
    }
}
.cc-revoke {
    display: none!important;
}

.o-section {
    z-index: 4;
    position: relative;

    &--dark {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0, .925);
            background: $black;
            z-index: -1;
        }
    }

    &--light {
        background: #212121;
    }
    &--white {
        background: #fff;
        color: #fff;

        .button.hollow{
            border-color: #000;
            color: #000;
        }
        p {
            color: #000;
        }
        h2{
            color: #000;
        }
    }

    &--gallery {
        padding-top: 0;
    }

    &--footer {
        padding: 0 0 5% 0;

        &:before {
            content: '';
            position: absolute;
            width: 105%;
            height: 100px;
            background: #212121;
            top: -50px;
            transform: rotate(-2deg);
            z-index: -1;
        }
    }

    &--opening-hours {
        dl {
            display: flex;
            flex-flow: row wrap;
            color: #d0d0d0;
            margin-bottom: 0px;

            &.today {
                font-weight: bold;
                color: $white;
            }
        }

        dt { 
            flex-basis: 40%;
        }

        dd {
            text-align: right;
            flex-grow: 1;
        }
    }
}

.c-hero-section {
    &__header {
        position: relative;
    }

    canvas {
        position: absolute;
        background: transparent;
        z-index: 3;
        left: 0;
        right: 0;
    }

    &__logo {
        position: fixed; 
        top: 100px;
        left: 0;
        right: 0;
        width: 50%;
        height: 450px;
        margin: 0 auto;
        background: url('../img/logo.svg') 50% no-repeat;
        background-repeat: no-repeat;
        filter: drop-shadow( 5px 5px 20px rgba(0,0,0, .7));
        z-index: 2;

        @include breakpoint(large only) {
            width: 20%;
        }
    }

    &__bg-1 {
        height: 100vh;
        width: 100%;
        background: url('../img/bg.jpg') 50% no-repeat;
        background-size: cover;
        z-index: 2;

        @include breakpoint(small only) {
            height: 80vh;
        }
    }

    &__bg-2 {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background: url('../img/bg-2.png') 50% no-repeat;
        background-size: cover;
        z-index: 2;

        @include breakpoint(small only) {
            height: 80vh;
        }
    }
    &__button {
        .button.large{
            @include breakpoint(small only) {
                font-size: 14px;
            }
        }
    }
}
.c-food {
    &--category{
        color: #d2d2d2;
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        &_spacer{
            margin-top: 30px;
            @include breakpoint(small only) {
                margin-top: 0px;
            }
        }
    }
    &--name{
        font-family: 'Playfair Display', serif;
        font-weight: 700;
        margin-bottom: 0px;
    }
    &--parts{
        font-family: 'Roboto', sans-serif;
    }
    &--price{
        font-family: 'Playfair Display', serif;
        font-weight: 700;

    }
}
